import { Box, Button, Chip, Container, Grid, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { ImageViewer } from 'react-image-viewer-dv';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../components/loader/Loader';
import CancelModal from '../components/modal/CancelModal';
import ConfirmModal from '../components/modal/ConfirmModal';
import { getDocument, getDocumentByAgent, reset } from '../features/landdocument/landdocumentSlice';
import { maskAddress, maskEmail, maskPhoneNumber } from '../helpers/maskedFunctions';

const LandDocument = () => {
  const dispatch = useDispatch();
  const [qrcode, setQrCode] = useState('');
  const [openConfirmModal, setConfirmModalOpen] = React.useState(false);
  const [openCancelModal, setCancelModalOpen] = React.useState(false);

  const handleOpenConfirmModal = () => setConfirmModalOpen(true);
  const handleCloseConfirmModal = () => setConfirmModalOpen(false);

  const handleOpenCancelModal = () => setCancelModalOpen(true);
  const handleCloseCancelModal = () => setCancelModalOpen(false);

  const verifiedImg = '/assets/icons/verified.png';
  const unverifiedImg = '/assets/icons/unverified.png';
  const pendingImg = '/assets/icons/pending.png';
  const { document: doc, isLoading, isError, isSuccess, message } = useSelector((state) => state.landdocument);
  const { docId } = useParams();
  const { user } = useSelector((state) => state.agentAuth);

  useEffect(() => {
    if (user.AgentType === 'Agent') {
      dispatch(getDocumentByAgent(docId));
    } else {
      dispatch(getDocument(docId));
    }
    reset();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      dispatch(reset());
    }, 3000);
  }, []);

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }

    if (isSuccess && message.includes('This Document has now been Verified')) {
      toast.success(message);
      handleCloseConfirmModal();
    }

    if (isSuccess && message.includes('This Document has now been Unverified')) {
      toast.error(message);
      handleCloseCancelModal();
    }

    dispatch(reset());
  }, [isError, isSuccess, message]);
  return (
    <>
      <Helmet>
        <title> Document | Lock.your.Land Dashboard </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            Document Details
          </Typography>
        </Stack>

        {isLoading && <Loader />}
        {doc && (
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12} xl={6}>
              <Box className="">
                <Box className="rounded-md shadow-lg px-3 py-5 bg-white mt-1">
                  <Typography varaint="h3" className=" mb-5 font-bold uppercase">
                    Buyers Information: <br />
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        Fullname: <br /> <span className="font-bold">{doc?.user?.Firstname}</span>
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        Lastname: <br /> <span className="font-bold">{doc?.user?.Lastname}</span>
                      </p>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        Gender: <br /> <span className="font-bold">{doc?.user?.Gender}</span>
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        Phone: <br /> <span className="font-bold">{maskPhoneNumber(`${doc?.user?.Phone}`)}</span>
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        Email: <br /> <span className="font-bold">{maskEmail(`${doc?.user?.Email}`)}</span>
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        Country: <br /> <span className="font-bold">{doc?.user?.Country}</span>
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        State: <br /> <span className="font-bold">{doc?.user?.State}</span>
                      </p>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        Address: <br /> <span className="font-bold">{maskAddress(`${doc?.user?.Address}`)}</span>
                      </p>
                    </Grid>
                    {/* <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        NIN: <br /> <span className="font-bold">{doc?.user?.Nin}</span>
                      </p>
                    </Grid> */}
                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">Status</p>
                      <Chip
                        label={
                          doc?.user?.VerifiedUser === 'completed'
                            ? 'Verified'
                            : doc?.user?.VerifiedUser === 'Not Verified'
                            ? 'Not Verified'
                            : doc?.user?.VerifiedUser === 'pending'
                            ? 'Pending'
                            : ''
                        }
                        size="small"
                        color={
                          doc?.user?.VerifiedUser === 'completed'
                            ? 'success'
                            : doc?.user?.VerifiedUser === 'Not Verified'
                            ? 'error'
                            : doc?.user?.VerifiedUser === 'pending'
                            ? 'warning'
                            : ''
                        }
                        icon={
                          <img
                            src={
                              doc?.user?.VerifiedUser === 'completed'
                                ? verifiedImg
                                : doc?.user?.VerifiedUser === 'Not Verified'
                                ? unverifiedImg
                                : doc?.user?.VerifiedUser === 'pending'
                                ? pendingImg
                                : ''
                            }
                            alt="verified img"
                            className="w-4 h-4"
                          />
                        }
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>

            {/* sellers information */}
            <Grid item xs={12} md={12} lg={12} xl={6}>
              <Box className="">
                <Box className="rounded-md shadow-lg px-3 py-5 bg-white mt-1">
                  <Typography varaint="h3" className=" mb-5 font-bold uppercase">
                    Sellers Information: <br />
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        Fullname: <br /> <span className="font-bold">{doc?.seller?.Firstname}</span>
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        Lastname: <br /> <span className="font-bold">{doc?.seller?.Lastname}</span>
                      </p>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        Gender: <br /> <span className="font-bold">{doc?.seller?.Gender}</span>
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        Phone: <br /> <span className="font-bold">{maskPhoneNumber(`${doc?.user?.Phone}`)}</span>
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        Email: <br /> <span className="font-bold">{maskEmail(`${doc?.user?.Email}`)}</span>
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        Country: <br /> <span className="font-bold">{doc?.seller?.Country}</span>
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        State: <br /> <span className="font-bold">{doc?.seller?.State}</span>
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        Address: <br /> <span className="font-bold">{maskAddress(`${doc?.seller?.Address}`)}</span>
                      </p>
                    </Grid>
                    {/* <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        NIN: <br /> <span className="font-bold">{doc?.seller?.Nin}</span>
                      </p>
                    </Grid> */}
                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">Status</p>
                      <Chip
                        label={
                          doc?.seller?.VerifiedUser === 'completed'
                            ? 'Verified'
                            : doc?.seller?.VerifiedUser === 'Not Verified'
                            ? 'Not Verified'
                            : doc?.seller?.VerifiedUser === 'pending'
                            ? 'Pending'
                            : ''
                        }
                        size="small"
                        color={
                          doc?.seller?.VerifiedUser === 'completed'
                            ? 'success'
                            : doc?.seller?.VerifiedUser === 'Not Verified'
                            ? 'error'
                            : doc?.seller?.VerifiedUser === 'pending'
                            ? 'warning'
                            : ''
                        }
                        icon={
                          <img
                            src={
                              doc?.seller?.VerifiedUser === 'completed'
                                ? verifiedImg
                                : doc?.seller?.VerifiedUser === 'Not Verified'
                                ? unverifiedImg
                                : doc?.seller?.VerifiedUser === 'pending'
                                ? pendingImg
                                : ''
                            }
                            alt="verified img"
                            className="w-4 h-4"
                          />
                        }
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>

            {/* land documents and images  */}
            <Grid item xs={12} md={12} lg={12} xl={6}>
              <Box className="">
                <Box className="rounded-md shadow-lg px-3 py-5 bg-white mt-1">
                  <Typography varaint="h3" className=" mb-5 font-bold uppercase">
                    Land Images: <br />
                  </Typography>
                  <Grid container spacing={2}>
                    {doc?.land?.Images?.map((image, index) => (
                      <Grid item xs={12} sm={12} md={4} key={index}>
                        <ImageViewer>
                          <img src={`${image}`} alt="land" className="w-full h-40 object-cover rounded-md" />
                        </ImageViewer>
                      </Grid>
                    ))}
                  </Grid>
                </Box>

                <Box className="rounded-md shadow-lg px-3 py-5 bg-white mt-1">
                  <Typography varaint="h3" className=" mb-5 font-bold uppercase">
                    Land Documents: <br />
                  </Typography>
                  <Grid container spacing={2}>
                    {doc?.land?.Documents?.map((image, index) => (
                      <Grid item xs={12} sm={12} md={4} key={index}>
                        <ImageViewer>
                          <img src={`${image}`} alt="land" className="w-full h-40 object-cover rounded-md" />
                        </ImageViewer>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Box>
            </Grid>

            {/* property information */}
            <Grid item xs={12} md={12} lg={12} xl={6}>
              <Box className="">
                <Box className="rounded-md shadow-lg px-3 py-5 bg-white mt-1">
                  <Typography varaint="h3" className=" mb-5 font-bold uppercase">
                    Land Information: <br />
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        Country: <br /> <span className="font-bold">{doc?.land?.Country}</span>
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        State: <br /> <span className="font-bold">{doc?.land?.State}</span>
                      </p>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        Area: <br /> <span className="font-bold">{doc?.land?.Area}</span>
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        Address: <br /> <span className="font-bold">{doc?.land?.Address}</span>
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        Price: <br />{' '}
                        <span className="font-bold">
                          {doc?.land?.Currency} {doc?.land?.Price.toLocaleString()}
                        </span>
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        Escrow: <br />{' '}
                        <span className="font-bold">
                          {doc?.land?.Currency} {doc?.land?.Escrow.toLocaleString()}
                        </span>
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        Size: <br />{' '}
                        <span className="font-bold">
                          {doc?.land?.Size}
                          {doc?.land?.Measurement}
                        </span>
                      </p>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        UniqueId: <br /> <span className="font-bold">{doc?.land?.UniqueId}</span>
                      </p>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900"> Document Status</p>
                      <Chip
                        label={
                          doc?.document?.Status === 'completed'
                            ? 'Verified'
                            : doc?.document?.Status === 'Not Verified'
                            ? 'Not Verified'
                            : doc?.document?.Status === 'pending'
                            ? 'Pending'
                            : ''
                        }
                        size="small"
                        color={
                          doc?.document?.Status === 'completed'
                            ? 'success'
                            : doc?.document?.Status === 'Not Verified'
                            ? 'error'
                            : doc?.document?.Status === 'pending'
                            ? 'warning'
                            : ''
                        }
                        icon={
                          <img
                            src={
                              doc?.document?.Status === 'completed'
                                ? verifiedImg
                                : doc?.document?.Status === 'Not Verified'
                                ? unverifiedImg
                                : doc?.document?.Status === 'pending'
                                ? pendingImg
                                : ''
                            }
                            alt="verified img"
                            className="w-4 h-4"
                          />
                        }
                      />
                    </Grid>
                    {doc?.document?.AgentUnbalancedPayment && (
                      <Grid item xs={6} sm={6} md={4}>
                        <Box>
                          <p className="text-blue-900 mt-3">
                            Payment Due: <br />{' '}
                            <span className="font-bold">{doc?.document?.AgentUnbalancedPayment.toLocaleString()}</span>
                          </p>
                        </Box>
                      </Grid>
                    )}
                    {doc?.document?.AgentUnbalancedPayment && (
                      <Grid item xs={6} sm={6} md={4}>
                        <Box>
                          <p className="text-blue-900"> Payment Status</p>
                          <Chip
                            label={
                              doc?.document?.AgentPaymentStatus === 'Full'
                                ? 'Full Payment'
                                : doc?.document?.AgentPaymentStatus === 'Half'
                                ? 'Half Payemnt'
                                : 'Unpaid'
                            }
                            size="small"
                            color={
                              doc?.document?.AgentPaymentStatus === 'Full'
                                ? 'success'
                                : doc?.document?.AgentPaymentStatus === 'Half'
                                ? 'warning'
                                : 'error'
                            }
                            icon={
                              <img
                                src={
                                  doc?.document?.AgentPaymentStatus === 'Full'
                                    ? verifiedImg
                                    : doc?.document?.AgentPaymentStatus === 'Half'
                                    ? pendingImg
                                    : unverifiedImg
                                }
                                alt="verified img"
                                className="w-4 h-4"
                              />
                            }
                          />
                        </Box>
                      </Grid>
                    )}
                    <Grid item xs={12} sm={12} md={12}>
                      <p className="text-blue-900">
                        Comment: <br /> <span className="font-bold">{doc?.document?.Comment}</span>
                      </p>
                    </Grid>
                  </Grid>

                  {doc?.document?.Status !== 'pending' && (
                    <Box className="mt-5">
                      <p className="text-blue-900">Document Validation Receipts: </p>

                      <Grid container spacing={4}>
                        <Grid item sm={12} md={12}>
                          <Grid container spacing={2}>
                            <Grid item sm={12} md={4}>
                              <Link to={`${doc?.document?.DocumentProof?.[0].LandRegistry}`}>
                                <Box className="p-2 rounded-md bg-green-700 text-center cursor-pointer">
                                  <Typography sx={{ fontSize: '14px', color: '#fff' }}>
                                    Land Registry Document
                                  </Typography>
                                </Box>
                              </Link>
                            </Grid>
                            <Grid item sm={12} md={4}>
                              <Link to={`${doc?.document?.DocumentProof?.[1]?.ProbateRegistory}`}>
                                <Box className="p-2 rounded-md bg-blue-700 text-center cursor-pointer">
                                  <Typography sx={{ fontSize: '14px', color: '#fff' }}>
                                    Probate Registry Document
                                  </Typography>
                                </Box>
                              </Link>
                            </Grid>
                            <Grid item sm={12} md={4}>
                              <Link to={`${doc?.document?.DocumentProof?.[2]?.CacSearch}`}>
                                <Box className="p-2 rounded-md bg-cyan-700 text-center cursor-pointer">
                                  <Typography sx={{ fontSize: '14px', color: '#fff' }}>CAC Search</Typography>
                                </Box>
                              </Link>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  )}

                  {doc?.document?.Validation === true && (
                    <Box className="flex ">
                      <ConfirmModal
                        open={openConfirmModal}
                        handleClose={handleCloseConfirmModal}
                        triggeredFrom="LandedProperty"
                      />
                      <CancelModal
                        open={openCancelModal}
                        handleClose={handleCloseCancelModal}
                        triggeredFrom="LandedProperty"
                      />
                      {doc?.document?.Status !== 'completed' && (
                        <Button
                          variant="outlined"
                          size="medium"
                          color="primary"
                          sx={{
                            textTransform: 'none',
                            marginTop: '3%',
                            fontWeight: 'bold',
                            marginRight: '1%',
                          }}
                          onClick={handleOpenConfirmModal}
                          disabled={isLoading}
                        >
                          {isLoading ? 'Please wait...' : 'Confirm Document Validity'}
                        </Button>
                      )}

                      {doc?.document?.Status !== 'Not Verified' && (
                        <Button
                          variant="outlined"
                          size="medium"
                          color="error"
                          sx={{
                            textTransform: 'none',
                            marginTop: '3%',
                            fontWeight: 'bold',
                          }}
                          onClick={handleOpenCancelModal}
                          disabled={isLoading}
                        >
                          {isLoading ? 'Please wait...' : 'Flag Document Validity'}
                        </Button>
                      )}
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        )}
      </Container>
    </>
  );
};

export default LandDocument;
