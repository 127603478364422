import { Box, Button, Chip, Container, Grid, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { ImageViewer } from 'react-image-viewer-dv';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../components/loader/Loader';
import CancelSurveyModal from '../components/modal/CancelSurveyModal';
import ConfirmSurveyModal from '../components/modal/ConfirmSurveyModal';
import { getDocument, reset } from '../features/propertydocument/propertydocumentSlice';
import { url } from '../utils/Schemas';
import { maskAddress, maskEmail, maskPhoneNumber } from '../helpers/maskedFunctions';

const SurveyPropertyDocuments = () => {
  const dispatch = useDispatch();
  const [qrcode, setQrCode] = useState('');
  const [openConfirmModal, setConfirmModalOpen] = React.useState(false);
  const [openCancelModal, setCancelModalOpen] = React.useState(false);

  const handleOpenConfirmModal = () => setConfirmModalOpen(true);
  const handleCloseConfirmModal = () => setConfirmModalOpen(false);

  const handleOpenCancelModal = () => setCancelModalOpen(true);
  const handleCloseCancelModal = () => setCancelModalOpen(false);

  const verifiedImg = '/assets/icons/verified.png';
  const unverifiedImg = '/assets/icons/unverified.png';
  const pendingImg = '/assets/icons/pending.png';
  const { document: doc, isLoading, isError, isSuccess, message } = useSelector((state) => state.propertydocument);
  const { docId } = useParams();

  useEffect(() => {
    dispatch(getDocument(docId));
    reset();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      dispatch(reset());
    }, 3000);
  }, []);

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }

    if (isSuccess && message.includes('This Survey has now been Verified')) {
      toast.success(message);
      handleCloseConfirmModal();
    }

    if (isSuccess && message.includes('This Survey has now been Unverified')) {
      toast.error(message);
      handleCloseCancelModal();
    }

    dispatch(reset());
  }, [isError, isSuccess, message]);
  return (
    <>
      <Helmet>
        <title> Document | Lock.your.Land Dashboard </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            Document Details
          </Typography>
        </Stack>

        {/* Modal for confirmation */}

        {/* //end modal */}

        {isLoading && <Loader />}

        {doc && (
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12} xl={6}>
              <Box className="">
                <Box className="rounded-md shadow-lg px-3 py-5 bg-white mt-1">
                  <Typography varaint="h3" className=" mb-5 font-bold uppercase">
                    Buyers Information: <br />
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={6} md={4}>
                      <p className="text-blue-900">
                        Fullname: <br /> <span className="font-bold">{doc?.user?.Firstname}</span>
                      </p>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                      <p className="text-blue-900">
                        Lastname: <br /> <span className="font-bold">{doc?.user?.Lastname}</span>
                      </p>
                    </Grid>

                    <Grid item xs={6} sm={6} md={4}>
                      <p className="text-blue-900">
                        Gender: <br /> <span className="font-bold">{doc?.user?.Gender}</span>
                      </p>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                      <p className="text-blue-900">
                        Phone: <br /> <span className="font-bold">{maskPhoneNumber(`${doc?.user?.Phone}`)}</span>
                      </p>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                      <p className="text-blue-900">
                        Email: <br /> <span className="font-bold">{maskEmail(`${doc?.user?.Email}`)}</span>
                      </p>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                      <p className="text-blue-900">
                        Country: <br /> <span className="font-bold">{doc?.user?.Country}</span>
                      </p>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                      <p className="text-blue-900">
                        State: <br /> <span className="font-bold">{doc?.user?.State}</span>
                      </p>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                      <p className="text-blue-900">
                        Address: <br /> <span className="font-bold">{maskAddress(`${doc?.user?.Address}`)}</span>
                      </p>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                      <p className="text-blue-900">
                        NIN: <br /> <span className="font-bold">{maskPhoneNumber(`${doc?.user?.Nin}`)}</span>
                      </p>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                      <p className="text-blue-900">Status</p>
                      <Chip
                        label={
                          doc?.user?.VerifiedUser === 'completed'
                            ? 'Verified'
                            : doc?.user?.VerifiedUser === 'Not Verified'
                            ? 'Not Verified'
                            : doc?.user?.VerifiedUser === 'pending'
                            ? 'Pending'
                            : null
                        }
                        size="small"
                        color={
                          doc?.user?.VerifiedUser === 'completed'
                            ? 'success'
                            : doc?.user?.VerifiedUser === 'Not Verified'
                            ? 'error'
                            : doc?.user?.VerifiedUser === 'pending'
                            ? 'warning'
                            : null
                        }
                        icon={
                          <img
                            src={
                              doc?.user?.VerifiedUser === 'completed'
                                ? verifiedImg
                                : doc?.user?.VerifiedUser === 'Not Verified'
                                ? unverifiedImg
                                : doc?.user?.VerifiedUser === 'pending'
                                ? pendingImg
                                : null
                            }
                            alt="verified img"
                            className="w-4 h-4"
                          />
                        }
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>

            {/* Sellers information */}
            <Grid item xs={12} md={12} lg={12} xl={6}>
              <Box className="">
                <Box className="rounded-md shadow-lg px-3 py-5 bg-white mt-1">
                  <Typography varaint="h3" className=" mb-5 font-bold uppercase">
                    Sellers Information: <br />
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={6} md={4}>
                      <p className="text-blue-900">
                        Fullname: <br /> <span className="font-bold">{doc?.seller?.Firstname}</span>
                      </p>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                      <p className="text-blue-900">
                        Lastname: <br /> <span className="font-bold">{doc?.seller?.Lastname}</span>
                      </p>
                    </Grid>

                    <Grid item xs={6} sm={6} md={4}>
                      <p className="text-blue-900">
                        Gender: <br /> <span className="font-bold">{doc?.seller?.Gender}</span>
                      </p>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                      <p className="text-blue-900">
                        Phone: <br /> <span className="font-bold">{maskPhoneNumber(`${doc?.seller?.Phone}`)}</span>
                      </p>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                      <p className="text-blue-900">
                        Email: <br /> <span className="font-bold">{maskEmail(`${doc?.seller?.Email}`)}</span>
                      </p>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                      <p className="text-blue-900">
                        Country: <br /> <span className="font-bold">{doc?.seller?.Country}</span>
                      </p>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                      <p className="text-blue-900">
                        State: <br /> <span className="font-bold">{doc?.seller?.State}</span>
                      </p>
                    </Grid>

                    <Grid item xs={6} sm={6} md={4}>
                      <p className="text-blue-900">
                        Address: <br /> <span className="font-bold">{maskAddress(`${doc?.seller?.Address}`)}</span>
                      </p>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                      <p className="text-blue-900">
                        NIN: <br /> <span className="font-bold">{maskPhoneNumber(`${doc?.seller?.Nin}`)}</span>
                      </p>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                      <p className="text-blue-900">Status</p>
                      <Chip
                        label={
                          doc?.seller?.VerifiedUser === 'completed'
                            ? 'Verified'
                            : doc?.seller?.VerifiedUser === 'Not Verified'
                            ? 'Not Verified'
                            : doc?.seller?.VerifiedUser === 'pending'
                            ? 'Pending'
                            : null
                        }
                        size="small"
                        color={
                          doc?.seller?.VerifiedUser === 'completed'
                            ? 'success'
                            : doc?.seller?.VerifiedUser === 'Not Verified'
                            ? 'error'
                            : doc?.seller?.VerifiedUser === 'pending'
                            ? 'warning'
                            : null
                        }
                        icon={
                          <img
                            src={
                              doc?.seller?.VerifiedUser === 'completed'
                                ? verifiedImg
                                : doc?.seller?.VerifiedUser === 'Not Verified'
                                ? unverifiedImg
                                : doc?.seller?.VerifiedUser === 'pending'
                                ? pendingImg
                                : null
                            }
                            alt="verified img"
                            className="w-4 h-4"
                          />
                        }
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>

            {/* property documents and images  */}
            <Grid item xs={12} md={12} lg={12} xl={6}>
              <Box className="">
                <Box className="rounded-md shadow-lg px-3 py-5 bg-white mt-1">
                  <Typography varaint="h3" className=" mb-5 font-bold uppercase">
                    Property Images: <br />
                  </Typography>
                  <Grid container spacing={2}>
                    {doc?.property?.Images?.map((image, index) => (
                      <Grid item xs={6} sm={6} md={4} key={index}>
                        <ImageViewer>
                          <img src={`${image}`} alt="property" className="w-full h-40 object-cover rounded-md" />
                        </ImageViewer>
                      </Grid>
                    ))}
                  </Grid>
                </Box>

                <Box className="rounded-md shadow-lg px-3 py-5 bg-white mt-1">
                  <Typography varaint="h3" className=" mb-5 font-bold uppercase">
                    Property Documents: <br />
                  </Typography>
                  <Grid container spacing={2}>
                    {doc?.property?.Documents?.map((image, index) => (
                      <Grid item xs={6} sm={6} md={4} key={index}>
                        <ImageViewer>
                          <img src={`${image}`} alt="property" className="w-full h-40 object-cover rounded-md" />
                        </ImageViewer>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Box>
            </Grid>

            {/* Property information */}
            <Grid item xs={12} md={12} lg={12} xl={6}>
              <Box className="">
                <Box className="rounded-md shadow-lg px-3 py-5 bg-white mt-1">
                  <Typography varaint="h3" className=" mb-5 font-bold uppercase">
                    Property Information: <br />
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={6} md={4}>
                      <p className="text-blue-900">
                        Country: <br /> <span className="font-bold">{doc?.property?.Country}</span>
                      </p>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                      <p className="text-blue-900">
                        State: <br /> <span className="font-bold">{doc?.property?.State}</span>
                      </p>
                    </Grid>

                    <Grid item xs={6} sm={6} md={4}>
                      <p className="text-blue-900">
                        Area: <br /> <span className="font-bold">{doc?.property?.Area}</span>
                      </p>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                      <p className="text-blue-900">
                        Address: <br /> <span className="font-bold">{doc?.property?.Address}</span>
                      </p>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                      <p className="text-blue-900">
                        Price: <br />{' '}
                        <span className="font-bold">
                          {doc?.property?.Currency} {doc?.property?.Price.toLocaleString()}
                        </span>
                      </p>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                      <p className="text-blue-900">
                        Escrow: <br />{' '}
                        <span className="font-bold">
                          {doc?.property?.Currency} {doc?.property?.Escrow.toLocaleString()}
                        </span>
                      </p>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                      <p className="text-blue-900">
                        Size: <br />{' '}
                        <span className="font-bold">
                          {doc?.property?.Size}
                          {doc?.property?.Measurement}
                        </span>
                      </p>
                    </Grid>

                    <Grid item xs={6} sm={6} md={4}>
                      <p className="text-blue-900">
                        SittingRooms: <br /> <span className="font-bold">{doc?.property?.SittingRooms}</span>
                      </p>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                      <p className="text-blue-900">
                        BedRooms: <br /> <span className="font-bold">{doc?.property?.BedRooms}</span>
                      </p>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                      <p className="text-blue-900">
                        RestRooms: <br /> <span className="font-bold">{doc?.property?.RestRooms}</span>
                      </p>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                      <p className="text-blue-900">
                        OtherRooms: <br /> <span className="font-bold">{doc?.property?.OtherRooms}</span>
                      </p>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                      <p className="text-blue-900">
                        UniqueId: <br /> <span className="font-bold">{doc?.property?.UniqueId}</span>
                      </p>
                    </Grid>

                    <Grid item xs={6} sm={6} md={4}>
                      <p className="text-blue-900"> Document Status</p>
                      <Chip
                        label={
                          doc?.document?.Status === 'completed'
                            ? 'Verified'
                            : doc?.document?.Status === 'Not Verified'
                            ? 'Not Verified'
                            : doc?.document?.Status === 'pending'
                            ? 'Pending'
                            : null
                        }
                        size="small"
                        color={
                          doc?.document?.Status === 'completed'
                            ? 'success'
                            : doc?.document?.Status === 'Not Verified'
                            ? 'error'
                            : doc?.document?.Status === 'pending'
                            ? 'warning'
                            : null
                        }
                        icon={
                          <img
                            src={
                              doc?.document?.Status === 'completed'
                                ? verifiedImg
                                : doc?.document?.Status === 'Not Verified'
                                ? unverifiedImg
                                : doc?.document?.Status === 'pending'
                                ? pendingImg
                                : null
                            }
                            alt="verified img"
                            className="w-4 h-4"
                          />
                        }
                      />
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                      <p className="text-blue-900"> Survey Status</p>
                      <Chip
                        label={
                          doc?.document?.SurveyStatus === 'completed'
                            ? 'Verified'
                            : doc?.document?.SurveyStatus === 'Not Verified'
                            ? 'Not Verified'
                            : doc?.document?.SurveyStatus === 'pending'
                            ? 'Pending'
                            : null
                        }
                        size="small"
                        color={
                          doc?.document?.SurveyStatus === 'completed'
                            ? 'success'
                            : doc?.document?.SurveyStatus === 'Not Verified'
                            ? 'error'
                            : doc?.document?.SurveyStatus === 'pending'
                            ? 'warning'
                            : null
                        }
                        icon={
                          <img
                            src={
                              doc?.document?.SurveyStatus === 'completed'
                                ? verifiedImg
                                : doc?.document?.SurveyStatus === 'Not Verified'
                                ? unverifiedImg
                                : doc?.document?.SurveyStatus === 'pending'
                                ? pendingImg
                                : null
                            }
                            alt="verified img"
                            className="w-4 h-4"
                          />
                        }
                      />
                    </Grid>

                    {doc?.document?.UnbalancedPayment && (
                      <Grid item xs={6} sm={6} md={4}>
                        <Box>
                          <p className="text-blue-900 mt-3">
                            Payment Due: <br />{' '}
                            <span className="font-bold">{doc?.document?.UnbalancedPayment.toLocaleString()}</span>
                          </p>
                        </Box>
                      </Grid>
                    )}
                    {doc?.document?.UnbalancedPayment && (
                      <Grid item xs={6} sm={6} md={4}>
                        <Box>
                          <p className="text-blue-900"> Payment Status</p>
                          <Chip
                            label={
                              doc?.document?.SurveyorPaymentStatus === 'Full'
                                ? 'Full Payment'
                                : doc?.document?.SurveyorPaymentStatus === 'Half'
                                ? 'Half Payemnt'
                                : 'Unpaid'
                            }
                            size="small"
                            color={
                              doc?.document?.SurveyorPaymentStatus === 'Full'
                                ? 'success'
                                : doc?.document?.SurveyorPaymentStatus === 'Half'
                                ? 'warning'
                                : 'error'
                            }
                            icon={
                              <img
                                src={
                                  doc?.document?.SurveyorPaymentStatus === 'Full'
                                    ? verifiedImg
                                    : doc?.document?.SurveyorPaymentStatus === 'Half'
                                    ? pendingImg
                                    : unverifiedImg
                                }
                                alt="verified img"
                                className="w-4 h-4"
                              />
                            }
                          />
                        </Box>
                      </Grid>
                    )}

                    <Grid item xs={6} sm={6} md={12}>
                      {doc?.document?.SurveyProof.length > 0 && (
                        <Box>
                          <p className="text-blue-900 mt-3">
                            Comment: <br /> <span className="font-bold">{doc?.document?.SurveyComment}</span>
                          </p>
                        </Box>
                      )}
                    </Grid>
                  </Grid>

                  {doc?.document?.SurveyProof.length > 0 && (
                    <Box className="mt-5">
                      <a target="_blank" rel="noreferrer" href={`${doc?.document?.SurveyProof}`}>
                        <Button
                          variant="outlined"
                          size="medium"
                          color="primary"
                          disableElevation
                          sx={{
                            textTransform: 'none',
                            marginTop: '3%',
                            fontWeight: 'bold',
                          }}
                        >
                          View Document
                        </Button>
                      </a>
                    </Box>
                  )}

                  <Box className="flex ">
                    <ConfirmSurveyModal open={openConfirmModal} handleClose={handleCloseConfirmModal} />
                    <CancelSurveyModal open={openCancelModal} handleClose={handleCloseCancelModal} />
                    {doc?.document?.SurveyStatus !== 'completed' && (
                      <Button
                        variant="outlined"
                        size="medium"
                        color="primary"
                        sx={{
                          textTransform: 'none',
                          marginTop: '3%',
                          fontWeight: 'bold',
                          marginRight: '1%',
                        }}
                        onClick={handleOpenConfirmModal}
                        disabled={isLoading}
                      >
                        {isLoading ? 'Please wait...' : 'Confirm Document Survey'}
                      </Button>
                    )}

                    {doc?.document?.SurveyStatus !== 'Not Verified' && (
                      <Button
                        variant="outlined"
                        size="medium"
                        color="error"
                        sx={{
                          textTransform: 'none',
                          marginTop: '3%',
                          fontWeight: 'bold',
                        }}
                        onClick={handleOpenCancelModal}
                        disabled={isLoading}
                      >
                        {isLoading ? 'Please wait...' : 'Flag Document Survey'}
                      </Button>
                    )}
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        )}
      </Container>
    </>
  );
};

export default SurveyPropertyDocuments;
