import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useFormik } from 'formik';
import { Grid, TextField } from '@mui/material';
import { ConfirmSchema } from '../../utils/Schemas';
import { unVerifyDocument, unVerifyDocumentByAgent } from '../../features/propertydocument/propertydocumentSlice';
import { unVerifyLandDocument, unVerifyLandDocumentByAgent } from '../../features/landdocument/landdocumentSlice';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function CancelModal({ open, handleClose, triggeredFrom }) {
  const dispatch = useDispatch();
  const { docId } = useParams();
  const { user } = useSelector((state) => state.agentAuth);
  const {
    values,
    errors,
    touched,
    isSubmitting,
    setFieldValue,
    setSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      LandRegistry: '',
      Comment: '',
      ProbateRegistry: '',
      CacSearch: '',
      AgentUnbalancedPayment: '',
    },
    validationSchema: ConfirmSchema,
    onSubmit: (values, actions) => {
      const formData = new FormData();
      formData.append('Comment', values.Comment);
      for (let index = 0; index < values.LandRegistry.length; index += 1) {
        formData.append('LandRegistory', values.LandRegistry[index]);
      }
      for (let index = 0; index < values.ProbateRegistry.length; index += 1) {
        formData.append('ProbateRegistory', values.ProbateRegistry[index]);
      }
      for (let index = 0; index < values.CacSearch.length; index += 1) {
        formData.append('CacSearch', values.CacSearch[index]);
      }
      formData.append('AgentUnbalancedPayment', values.AgentUnbalancedPayment.replace(/,/g, ''));

      if (user.AgentType === 'Agent' && triggeredFrom === 'LandedProperty') {
        dispatch(unVerifyLandDocumentByAgent({ formData, docId }));
      } else if (user.AgentType === 'Agent' && triggeredFrom !== 'LandedProperty') {
        dispatch(unVerifyDocumentByAgent({ formData, docId }));
      } else if (user.AgentType !== 'Agent' && triggeredFrom === 'LandedProperty') {
        dispatch(unVerifyLandDocument({ formData, docId }));
      } else {
        dispatch(unVerifyDocument({ formData, docId }));
      }
    },
  });

  const handleLandRegistryUpload = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFieldValue('LandRegistry', event.target.files);
  };

  const handleProbateRegistryUpload = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFieldValue('ProbateRegistry', event.target.files);
  };

  const handleCacSearch = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFieldValue('CacSearch', event.target.files);
  };

  const formatNumber = (value) => {
    const number = value.replace(/\D/g, '');
    return number.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const handlePriceChange = (event, name) => {
    const rawValue = event.target.value;
    const numericValue = rawValue.replace(/[^0-9]/g, '');
    const formattedValue = formatNumber(numericValue);

    setFieldValue(name, formattedValue);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Upload Verification Receipts and Reports:
          </Typography>
          <Box
            onSubmit={handleSubmit}
            component="form"
            sx={{
              '& .MuiTextField-root': { width: '100%' },
              padding: 0,
              margin: '5% 0%',
            }}
            noValidate
            autoComplete="off"
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <h3 htmlFor="">Comments</h3>
                <span className="text-gray-600 text-[10px] leading-tight">
                  (*) Enter comments or additional information here if the property requires special processes to
                  confirm ownership
                </span>
                <div>
                  <textarea
                    label="Comment"
                    id="Comment"
                    rows={5}
                    cols={10}
                    className="w-full rounded-md outline-none border p-3"
                    onChange={handleChange}
                    value={values.Comment}
                    onBlur={handleBlur}
                  />
                </div>
                {errors.Comment && touched.Comment && (
                  <Typography sx={{ fontSize: '9px', color: 'red' }}>{errors.Comment}</Typography>
                )}
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <div>
                  <h3 htmlFor="">Balanced Payment</h3>
                  <span className="text-gray-600 text-[10px] leading-tight">
                    (*) Please enter the balance to be paid for the process. Enter 0.00 if not applicable
                  </span>
                  <TextField
                    id="AgentUnbalancedPayment"
                    size=""
                    inputProps={{ style: { fontSize: 12 } }}
                    onChange={(e) => handlePriceChange(e, 'AgentUnbalancedPayment')}
                    value={values.AgentUnbalancedPayment}
                    onBlur={(e) => handlePriceChange(e, 'AgentUnbalancedPayment')}
                  />
                </div>
                {errors.UnbalancedPayment && touched.UnbalancedPayment && (
                  <Typography sx={{ fontSize: '9px', color: 'red' }}>{errors.AgentUnbalancedPayment}</Typography>
                )}
              </Grid>

              <Grid item xs={12} sm={4} md={4}>
                <h3>Lands Registry</h3>
                <div className="border-2 p-2 ">
                  <input
                    id="LandRegistry"
                    type="file"
                    style={{ display: '' }}
                    accept=".pdf"
                    onChange={handleLandRegistryUpload}
                  />
                </div>

                {errors.LandRegistry && touched.LandRegistry && (
                  <Typography sx={{ fontSize: '12px', color: 'red' }}>{errors.LandRegistry}</Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <h3>Probate Registry</h3>
                <div className="border-2 p-2 ">
                  <input
                    id="ProbateRegistry"
                    type="file"
                    style={{ display: '' }}
                    accept=".pdf"
                    onChange={handleProbateRegistryUpload}
                  />
                </div>

                {errors.ProbateRegistry && touched.ProbateRegistry && (
                  <Typography sx={{ fontSize: '12px', color: 'red' }}>{errors.ProbateRegistry}</Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <h3>CAC Search</h3>
                <div className="border-2 p-2 ">
                  <input id="CacSearch" type="file" style={{ display: '' }} accept=".pdf" onChange={handleCacSearch} />
                </div>

                {errors.CacSearch && touched.CacSearch && (
                  <Typography sx={{ fontSize: '12px', color: 'red' }}>{errors.CacSearch}</Typography>
                )}
              </Grid>
            </Grid>

            <Button
              variant="contained"
              disabled={isSubmitting}
              sx={{
                bgcolor: '#1e40af',
                textTransform: 'none',
                fontWeight: 'bold',
                marginTop: '3%',
              }}
              className="bg-blue-700 w-full"
              size="medium"
              type="submit"
            >
              {isSubmitting ? 'Please wait...' : 'Flag Document Validity'}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
