import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

// @mui

import { Container, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// components
import {
  fetchhVerifiedLandDocsByAgent,
  fetchhVerifiedLandDocsBySurveyor,
} from '../features/landdocuments/landdocumentSlice';
import {
  fetchVerifiedPropertyDocsByAgent,
  fetchVerifiedPropertyDocsBySurveyor,
} from '../features/propertydocuments/propertydocumentSlice';

// sections
import Loader from '../components/loader/Loader';
import { AppWidgetSummary } from '../sections/@dashboard/app';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const {
    user: { loggedIn },
  } = useSelector((state) => state.agentAuth);
  const navigate = useNavigate();

  const { docs: propertyDocs, isLoading, isError, message } = useSelector((state) => state.propertydocuments);
  const { docs: landDocs } = useSelector((state) => state.landdocuments);

  const { user } = useSelector((state) => state.agentAuth);

  const dispatch = useDispatch();

  useEffect(() => {
    if (user.AgentType === 'Agent') {
      dispatch(fetchVerifiedPropertyDocsByAgent());
      dispatch(fetchhVerifiedLandDocsByAgent());
    } else {
      dispatch(fetchVerifiedPropertyDocsBySurveyor());
      dispatch(fetchhVerifiedLandDocsBySurveyor());
    }
  }, []);

  useEffect(() => {
    const currentTime = new Date();
    const timeDifferenceInMs = currentTime - loggedIn;
    const timeDifferenceInHours = timeDifferenceInMs / (1000 * 60 * 60);

    const logoutThreshold = 24; // 24 hours
    // Check if the future time is greater than the current time
    if (timeDifferenceInHours >= logoutThreshold) {
      localStorage.removeItem('Surveyuser');
      navigate('/login');
    }
  }, []);

  const theme = useTheme();

  return (
    <>
      <Helmet>
        <title> Dashboard | Lock your Land </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>

        {isLoading ? (
          <Loader />
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={4}>
              <Link to="/dashboard/verifiedDocuments">
                <AppWidgetSummary title="Verified Documents" color="info" icon={'emojione-v1:document'} />
              </Link>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Link to="/dashboard/unverifiedDocuments">
                <AppWidgetSummary title="UnVerified Documents" icon={'emojione-v1:document'} color="success" />
              </Link>
            </Grid>
            {/* 
            <Grid item xs={12} sm={6} md={4}>
              <AppWidgetSummary title="Settings" total="" icon={'ic:twotone-settings'} color="success" />
            </Grid> */}

            {/* <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate
              title="News Update"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: faker.name.jobTitle(),
                description: faker.name.jobTitle(),
                image: `/assets/images/covers/cover_${index + 1}.jpg`,
                postedAt: faker.date.recent(),
              }))}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline
              title="Order Timeline"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: [
                  '1983, orders, $4220',
                  '12 Invoices have been paid',
                  'Order #37745 from September',
                  'New order placed #XF-2356',
                  'New order placed #XF-2346',
                ][index],
                type: `order${index + 1}`,
                time: faker.date.past(),
              }))}
            />
          </Grid> */}
          </Grid>
        )}
      </Container>
    </>
  );
}
